import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/styles";

import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
import ProductCard from "../Products/Components/ProductCard";
import CategoryCard from "./CategoryCard";
import Breadcrumb from "../Breadcrumb";

import { addToWishlist, removeFromWishlist } from "../../app/actions";
import styles from "./styles";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const queryObjectProducts = (param, next) => ({
  query: `
  {
    products(first: 50, where: {search: "${param}", status: PUBLISH}, after: "${next}") {
      edges {
        node {
          id
          uri
          slug
          title
          brands {
            nodes {
              name
              BrandExtras {
                logo {
                  id
                  sourceUrl
                }
              }
            }
          }
          product {
            shortDescription
            code
            productGallery {
              sourceUrl(size: LARGE)
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  `,
});

const queryObjectCategories = (param, next) => ({
  query: `
  {
    productCategories(first: 100, where: {search: "${param}"}, after: "${next}") {
      edges {
        node {
          id
          uri
          name
          slug
          productCategory {
            categoryImage {
              sourceUrl(size: LARGE)
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  `,
});

class ProductSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: this.props.prefill,
      products: [],
      categories: [],
      query: this.props.prefill,
      searchingProducts: false,
      searchingCategories: false,
    };

    this.SearchQuery = this.SearchQuery.bind(this);
    this.ProductResults = this.ProductResults.bind(this);
    this.CategoryResults = this.CategoryResults.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (this.props.prefill) {
      this.fetchProducts();
      this.fetchCategories();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wishlist !== this.props.wishlist) {
      Cookies.set("wishlist", this.props.wishlist);
    }
  }

  fetchProducts(next = "") {
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectProducts(this.state.search, next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          products: res.data.data.products.edges.map((node) => node.node),
          next: res.data.data.products.pageInfo.endCursor,
          hasNextPage: res.data.data.products.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        this.setState(
          {
            products: this.state.products.concat(data.products),
          },
          () => {
            if (data.hasNextPage) {
              this.fetchProducts(data.next);
            } else {
              this.setState({
                searchingProducts: false,
              });
            }
          }
        );
      });
  }

  fetchCategories(next = "") {
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCategories(this.state.search, next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          categories: res.data.data.productCategories.edges.map(
            (node) => node.node
          ),
          next: res.data.data.productCategories.pageInfo.endCursor,
          hasNextPage: res.data.data.productCategories.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        if (!this.state.search) return data;

        return {
          categories: data.categories.filter((category) => {
            if (
              category.name
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            )
              return true;
            return false;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        this.setState(
          {
            categories: this.state.categories.concat(data.categories),
          },
          () => {
            if (data.hasNextPage) {
              this.fetchCategories(data.next);
            } else {
              this.setState({
                searchingCategories: false,
              });
            }
          }
        );
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.search) return;

    this.setState({
      query: this.state.search,
      products: [],
      categories: [],
      searchingProducts: true,
      searchingCategories: true,
    });
    this.fetchProducts();
    this.fetchCategories();
  }

  wishlistHandler(id) {
    const { wishlist, addToWishlist, removeFromWishlist } = this.props;

    if (wishlist.includes(id)) {
      removeFromWishlist(id);
    } else {
      addToWishlist(id);
    }
  }

  ProductResults() {
    const { products, query } = this.state;
    const { classes, wishlist } = this.props;

    if (!products.length) return null;

    return (
      <section className={classes.productResults}>
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "after-line",
            content: "Product results",
            backgroundColor: "",
            level: 6,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <Grid container spacing={3}>
          {products.map((product, index) => {
            const image =
              (product.product.productGallery &&
                product.product.productGallery[0].sourceUrl) ||
              null;

            let wishClass = "";

            if (wishlist.includes(String(product.product.code))) {
              wishClass = "added";
            }

            if (product.product.variations) {
              product.product.variations.forEach((variation) => {
                if (wishlist.includes(variation.variantCode)) {
                  wishClass = "added";
                }
              });
            }

            return (
              <ProductCard
                key={index}
                code={product.product.code}
                brands={product.brands}
                title={product.title}
                description={product.product.shortDescription}
                id={product.id}
                link={product.uri}
                linkState={{ query }}
                wishClass={wishClass}
                wishlistHandler={(id) => this.wishlistHandler(id)}
              >
                {image && <img src={image} alt={product.title} />}
              </ProductCard>
            );
          })}
        </Grid>
      </section>
    );
  }

  CategoryResults() {
    const { categories } = this.state;
    const { classes } = this.props;

    if (!categories.length) return null;

    return (
      <section className={classes.categoryResults}>
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            backgroundColor: "",
            className: "after-line",
            content: "Category results",
            level: 6,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <Grid container spacing={3}>
          {categories.map((category, index) => {
            const image =
              (category.productCategory.categoryImage &&
                category.productCategory.categoryImage.sourceUrl) ||
              null;
            return (
              <CategoryCard
                key={index}
                name={category.name}
                link={category.uri}
                image={image}
              />
            );
          })}
        </Grid>
      </section>
    );
  }

  SearchQuery() {
    const { categories, products, query } = this.state;
    const { classes } = this.props;

    if (!categories.length && !products.length) return null;

    return (
      <h3 className={classes.queryTitle}>
        Search results: <span>{query}</span>
      </h3>
    );
  }

  renderError() {
    return (
      <>
        <p>Your search hasn’t matched any of our products. </p>
        <p>Helpful hints to improve your product search:</p>
        <p>- Ensure spelling is correct</p>
        <p>- Try similar words</p>
        <p>- Use fewer words when searching</p>
      </>
    );
  }

  render() {
    const {
      search,
      searchingProducts,
      searchingCategories,
      products,
      categories,
      query,
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Breadcrumb
          type="category"
          current={{
            title: "Product Search",
            slug: "product-search",
            uri: "/product-search",
          }}
        />
        <section className={"page-title " + classes.productSearchTitle}>
          <div className="heading">
            <CoreHeadingBlock
              attributes={{
                align: "",
                anchor: "",
                className: "",
                content: "Product search",
                level: 1,
                textColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
          <div className="search">
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="search">Search for your product</label>
              <input
                type="text"
                name="search"
                placeholder="Product Number, or Product Name"
                value={search}
                minLength={2}
                required
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              <input type="submit" value="search" />
            </form>
          </div>
        </section>
        <this.SearchQuery />
        {(searchingProducts || searchingCategories) && (
          <div className={classes.searching}>Searching...</div>
        )}
        {!searchingProducts && !searchingCategories && <this.ProductResults />}
        {!searchingProducts && !searchingCategories && <this.CategoryResults />}
        {query &&
          !products.length && !categories.length &&
          !searchingProducts && !searchingCategories &&
          this.renderError()}
      </div>
    );
  }
}

export default withStyles(styles)(connector(ProductSearch));
